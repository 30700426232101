/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
const PostCard = ({ data }) => (
  <article 
    className="post-card"
    sx={{
      bg: 'cardBg'
    }}
  >
    {data.image ? 
      (
        <Link to={data.slug}>
          <GatsbyImage 
            image={getImage(data.image)} 
            alt={data.title + ' - Featured image'}
            className="featured-image"
          />
        </Link>
      ) : ""
    }
    {/* <div className="post-content">
      <h2 className="title">
        <Link 
          to={data.slug}
          sx={{
            variant: 'links.postLink'
          }}
        >
          {data.title}
        </Link>
      </h2>
      <p 
        className="meta"
        sx={{
          color: 'muted',
        }}
      >
        <time>{data.date}</time>
      </p>
    </div> */}
  </article>
)

export default PostCard